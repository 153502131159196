// import React fr
import React from "react"
import {Link} from "gatsby"
import { Container, Row, Col, Button, Form, FormControl, InputGroup } from "react-bootstrap"
import {isMobile} from "react-device-detect"

class CustomFooterMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      searchText: "",
    };
    this.changeSearchText = this.changeSearchText.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  changeSearchText(e){
    this.setState({searchText: e.target.value})
  }

  searchSubmit(e){
    e.preventDefault()
    window.open("/search/?search="+this.state.searchText, "_self");
  }

  render(){
    return(
      <React.Fragment>
        {!isMobile &&
          <Row noGutters className="unhistoried-bottom-footer-row">
            <Col md={6} sm={6} xs={6} lg={6} className="footer-col search-submit">
              <div className="unhistoried-button-container">
                <Row noGutters>
                  <Link to="/submit/" className="navbar-link">
                      <Button className="mr-auto unhistoried-bottom-button btn-lg" variant="dark">
                        <h4 className="unhistoried-bottom-button-text">Submit</h4>
                      </Button>
                  </Link>
                </Row>
              </div>
            </Col>
            <Col md={6} sm={6} xs={6} lg={6} className="footer-col search-submit">
              <div className="unhistoried-button-container">
                <Row noGutters>
                  {!this.state.isActive &&
                    <Button
                      onMouseOver={() => this.setState({ isActive: !this.state.isActive })}
                      className="ml-auto unhistoried-bottom-button btn-lg" variant="dark">
                        <h4 className="unhistoried-bottom-button-text">Search</h4>
                    </Button>
                  }
                  {this.state.isActive &&
                    <Form onSubmit={this.searchSubmit} inline onMouseLeave={() => this.setState({ isActive: !this.state.isActive })} className="ml-auto">
                      <InputGroup className="mb-3">
                        <FormControl className="unhistoried-search-bar form-control-lg"
                          placeholder="Type Here..."
                          aria-label="Type Here..."
                          aria-describedby="basic-addon2"
                          type="text" onChange={this.changeSearchText}
                        />
                        <Button variant="primary" className="unhistoried-bottom-button-search btn-lg" id="button-addon2" type="submit">
                          ⟶
                        </Button>
                      </InputGroup>
                    </Form>
                  }
                </Row>
              </div>
            </Col>
          </Row>
        }
      </React.Fragment>
    )
  }
}
export default CustomFooterMenu
