
import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import Navbar from "./navBar"
import CustomFooterMenu from "./footer"

const Layout = ({ children, pageInfo, active }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        contentfulGeneral(contentful_id: {eq: "1fDxT0SD7yWPCDmLFmmaAx"}) {
          logo {
            gatsbyImageData(placeholder: NONE, height: 60, layout: FIXED)
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main">
          <Navbar pageInfo={pageInfo} active={active} logoImage={data.contentfulGeneral.logo.gatsbyImageData}/>
          <Row noGutters>
            <Col>
              <Container>
                <main className="layoutMain">{children}</main>
              </Container>
            </Col>
          </Row>
          <CustomFooterMenu/>
        </Container>
      </>
    )}
  />
)

export default Layout
