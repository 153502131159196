// import React fr
import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Navbar, Nav } from "react-bootstrap"
import {isMobile} from "react-device-detect"

class CustomNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.navbarItem = this.navbarItem.bind(this);
  }

  navbarItem() {
    console.log(this.props.logoImage);
    return(
      <React.Fragment>
        <Nav className="mr-auto">
          <Link to="/" className="navbar-link">
            <Nav.Link as="span" eventKey="login">
              <GatsbyImage image={getImage(this.props.logoImage)} height="60" />
            </Nav.Link>
          </Link>
        </Nav>
        { isMobile &&
          <Nav className="ml-auto unhistoried-navbar-mobile">
            {
              this.props.active !== "collection" &&
              <Link to="/collections/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Collection</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active === "collection" &&
              <Link to="/collections/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Collection</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active === "article" &&
              <Link to="/articles/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Article</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active !== "article" &&
              <Link to="/articles/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Article</h4>
                </Nav.Link>
              </Link>
            }

            {
              this.props.active === "publication" &&
              <Link to="/publications/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Publication</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active !== "publication" &&
              <Link to="/publications/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Publication</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active === "about" &&
              <Link to="/about/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">About</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active !== "about" &&
              <Link to="/about/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">About</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active === "contact" &&
              <Link to="/contact/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Contact</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active !== "contact" &&
              <Link to="/contact/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Contact</h4>
                </Nav.Link>
              </Link>
            }
            {isMobile &&
              <React.Fragment>
              <Link to="/submit/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Submit</h4>
                </Nav.Link>
              </Link>
              <Link to="/search/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Search</h4>
                </Nav.Link>
              </Link>
              </React.Fragment>
            }
          </Nav>
        }

        { !isMobile &&
          <Nav className="ml-auto">
            {
              this.props.active !== "collection" &&
              <Link to="/collections/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Collection</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active === "collection" &&
              <Link to="/collections/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Collection</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active === "article" &&
              <Link to="/articles/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Article</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active !== "article" &&
              <Link to="/articles/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Article</h4>
                </Nav.Link>
              </Link>
            }

            {
              this.props.active === "publication" &&
              <Link to="/publications/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Publication</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active !== "publication" &&
              <Link to="/publications/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Publication</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active === "about" &&
              <Link to="/about/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">About</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active !== "about" &&
              <Link to="/about/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">About</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active === "contact" &&
              <Link to="/contact/" className="navbar-link navbar-nav-selected">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Contact</h4>
                </Nav.Link>
              </Link>
            }
            {
              this.props.active !== "contact" &&
              <Link to="/contact/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Contact</h4>
                </Nav.Link>
              </Link>
            }
            {isMobile &&
              <React.Fragment>
              <Link to="/submit/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Submit</h4>
                </Nav.Link>
              </Link>
              <Link to="/search/" className="navbar-link">
                <Nav.Link as="span" eventKey="login">
                  <h4 className="navbar-menu-item">Search</h4>
                </Nav.Link>
              </Link>
              </React.Fragment>
            }
          </Nav>
        }

      </React.Fragment>
    )
  }

  render(){
    return(
      <React.Fragment>
        { isMobile &&
          <Navbar variant="light" expand="md" id="site-navbar-mobile">
            {/* <Container> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              { this.navbarItem() }
            </Navbar.Collapse>
          </Navbar>
        }
        { !isMobile &&
          <Navbar variant="light" expand="md" id="site-navbar">
            {/* <Container> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              { this.navbarItem() }
            </Navbar.Collapse>
          </Navbar>
        }
      </React.Fragment>
    )
  }
}


export default CustomNavbar
